import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, RouteConfig, Router } from 'aurelia-router';
import { UxBlade } from 'components/layout/ux-blade/ux-blade';
import { saveAs } from 'file-saver';
import { AuthenticationProvider } from 'providers/authentication-provider';
import {
    CaseBook,
    CaseBookContentBlock,
    CaseBookContentBlockBodyFormats, CaseBookContentBlockResponse,
    CaseBookContentBlockTypes,
    CaseBookReferencedEntity,
    CaseBookReferencedEntityTypes,
    CaseBookStatusTypes,
    CasesApiClient, GetCaseBookResponse,
    Investigation,
    InvestigationsApiClient,
    InvestigationStatus
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import Swal from 'sweetalert2';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { BusinessMapsApiClient } from './../../../services/cyber-api';
import { CaseUtilities } from '../../../utilities/case-utilities';

@autoinject
export class Casebook {
    private tab: string = 'activity';
    private caseBookId: string;
    private caseBook: GetCaseBookResponse;
    private enableBusinessFeatures: boolean = false;
    private investigation: Investigation;
    private blade: UxBlade;
    private directLink: string;

    // Enums, to be used in the view
    private caseStatuses: typeof CaseBookStatusTypes = CaseBookStatusTypes;
    private investigationStatuses: typeof InvestigationStatus = InvestigationStatus;
    private blockTypes: typeof CaseBookContentBlockTypes = CaseBookContentBlockTypes;
    private bodyFormats: typeof CaseBookContentBlockBodyFormats = CaseBookContentBlockBodyFormats;

    private activeCommentTab: 'write' | 'preview' = 'write';
    private newComment: string = '';
    private isNewCommentPrivate: boolean = false;

    private referencedThreats: CaseBookReferencedEntity[];
    private referencedAttachments: CaseBookReferencedEntity[];

    constructor(
        private businessMapsApi: BusinessMapsApiClient,
        private casesApi: CasesApiClient,
        private state: StateApi,
        private router: Router,
        private investigationsApi: InvestigationsApiClient,
        private authProvider: AuthenticationProvider
    ) { }

    private activate(params: any, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction): void {
        this.caseBookId = params.id;
        // Generate a direct link to this Case based on its id
        this.directLink = this.router.generate('case-book', { id: this.caseBookId }, { absolute: true });
    }

    private async attached(): Promise<void> {
        await this.retrieveCaseBook();

        // Enable/Disable 'business' features
        this.enableBusinessFeatures = await CaseUtilities.shouldEnableBusinessFeatures(this.businessMapsApi, this.state.company());
    }

    private async afterBladeHide(): Promise<void> {
        await this.retrieveCaseBook();
    }

    private async retrieveCaseBook(): Promise<void> {
        this.caseBook = undefined;
        this.referencedThreats = undefined;
        this.referencedAttachments = undefined;
        this.investigation = undefined;

        try {
            const extendedCaseBook = await this.casesApi.getById(this.caseBookId, this.state.company());
            this.investigation = extendedCaseBook.investigation;

            // Convert the extended CaseBook to a regular CaseBook model
            this.caseBook = new GetCaseBookResponse({
                ...extendedCaseBook,
                status: extendedCaseBook.status.toString() as CaseBookStatusTypes
            });

            this.caseBook.contentBlocks.sort((cb1, cb2) => cb1.createdAt.valueOf() - cb2.createdAt.valueOf());

            this.referencedThreats = this.caseBook.referencedEntities.filter((re) => re.entityType === CaseBookReferencedEntityTypes.Threat);
            this.referencedAttachments = this.caseBook.referencedEntities.filter((re) => re.entityType === CaseBookReferencedEntityTypes.Attachment);
        } catch (error) {
            Toast.error('Case could not be loaded');
            return;
        }
    }

    private async close(): Promise<void> {
        // The user may not close the case when an investigation is either requested or in progress
        if (this.caseBook.investigationId
            && this.investigation
            && (this.investigation.status === InvestigationStatus.Requested
                || this.investigation.status === InvestigationStatus.Inprogress)
        ) return;

        const response = await Swal.fire({
            title: 'Close case',
            html: 'Are you sure you want to close this case?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, close case',
        });

        if (response.value)
            try {
                this.caseBook = await this.casesApi.close(this.caseBookId, this.state.company());
                Toast.success('Case has been closed successfully');
            } catch (error) {
                Toast.error('Could not close case');
            }
    }

    private async delete(): Promise<void> {
        // The user may not delete the case when an investigation is either requested or in progress
        if (this.caseBook.investigationId
            && this.investigation
            && (this.investigation.status === InvestigationStatus.Requested
                || this.investigation.status === InvestigationStatus.Inprogress)
        ) return;

        const response = await Swal.fire({
            title: 'Delete case',
            html: 'Are you sure you want to delete this case?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, delete case',
        });

        if (response.value)
            try {
                await this.casesApi.delete(this.caseBookId, this.state.company());
                this.router.navigateToRoute('cases');
                Toast.success('Case has been successfully deleted permanently');
            } catch (error) {
                Toast.error('Could not delete case');
            }
    }

    private async postComment(comment: string, isPrivate: boolean = false): Promise<void> {
        const contentBlock = new CaseBookContentBlock({
            body: comment,
            blockType: CaseBookContentBlockTypes.UserComment,
            bodyFormat: CaseBookContentBlockBodyFormats.Markdown,
            isPrivate
        });

        try {
            // Add the content block to the case
            await this.casesApi.addBlock(this.caseBookId, this.state.company(), contentBlock);

            // Retrieve the updated Case
            await this.retrieveCaseBook();

            // Add a temporary comment to the CaseBook's content blocks as it might take a few seconds to actually end
            // up in the CaseBook on the server itself.
            this.caseBook.contentBlocks.push(
                new CaseBookContentBlockResponse({
                    body: comment,
                    blockType: CaseBookContentBlockTypes.UserComment,
                    bodyFormat: CaseBookContentBlockBodyFormats.Markdown,
                    isPrivate: false,
                    createdBy: this.authProvider.profile.sub,
                    createdByUsername: this.authProvider.profile.name.split("@")[0]?.replace("."," "),
                    createdAt: new Date(),
                    updatedBy: 'temp',
                    updatedAt: new Date()
                })
            )
        } catch (error) {
            Toast.error('Comment could not be added to case, please try again later');
        } finally {
            this.resetComment();
        }
    }

    private async requestInvestigation(): Promise<void> {
        await this.blade.show();
    }

    private async share(): Promise<void> {
        const response = await Swal.fire({
            title: 'Submit Case to the SOC',
            html: 'Submitting the Case to the SOC cannot be undone. Are you sure you want to submit your Case?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, submit case',
        });

        Toast.error('Principle of private/public cases has been deprecated');

        // if (response.value)
        //     try {
        //         this.caseBook = await this.casesApi.share(this.caseBookId, this.state.company());
        //         Toast.success('Case has been successfully submitted to the SOC');
        //     } catch (error) {
        //         Toast.error('Could not submit case to SOC');
        //     }
    }

    /**
     * Copies the Case's id to the clipboard
     */
    private copyId(): void {
        try {
            Utilities.copyToClipboard(this.caseBookId);
        } catch (error) {
            Toast.warning('Unable to copy Case Id to clipboard');
            return;
        }

        Toast.info('Case Id copied to clipboard');
    }

    /**
     * Generates a link to this specific Case and copies it to the clipboard
     */
    private copyLink(): void {
        try {
            Utilities.copyToClipboard(this.directLink);
        } catch (error) {
            Toast.warning('Unable to copy link to Case to clipboard');
            return;
        }

        Toast.info('Link to Case copied to clipboard');
    }

    private resetComment(): void {
        this.newComment = '';
        this.isNewCommentPrivate = false;
        this.activeCommentTab = 'write';
    }

    private async updateComment(block: CaseBookContentBlock, comment: string): Promise<void> {
        try {
            // Hotfix: we encountered issue where updatedAt was '0000-12-31T23:40:28.000Z'
            // This broke the call to the API. Should investigate that someday...
            block.updatedAt = new Date();

            block.body = comment;
            await this.casesApi.updateBlock(this.caseBookId, this.state.company(), block);
        } catch (error) {
            Toast.error(`Updating the comment was unsuccessful, please try again later.`);
        }
    }

    private async deleteComment(block: CaseBookContentBlock): Promise<void> {
        const response = await Swal.fire({
            title: 'Delete comment',
            html: 'Are you sure you want to delete this comment?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, delete comment',
        });

        if (response.value)
            try {
                await this.casesApi.deleteBlock(this.caseBookId, block.id, this.state.company());
                await this.retrieveCaseBook();
                Toast.success('Comment has successfully been deleted');
            } catch (error) {
                Toast.error('Could not delete comment');
            }
    }

    private async detachEntity(referencedEntity: CaseBookReferencedEntity): Promise<void> {
        const response = await Swal.fire({
            title: `Detach ${referencedEntity.label}`,
            html: `Are you sure you want to detach ${referencedEntity.label} from the Case?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, detach',
        });

        if (response.value)
            try {
                await this.casesApi.detachEntity(this.caseBookId, referencedEntity.entityId, this.state.company());
                await this.retrieveCaseBook();
                Toast.success(`${referencedEntity.label} has successfully been detached`);
            } catch (error) {
                Toast.error(`Could not detach ${referencedEntity.label}`);
            }
    }

    private async updateDescription(description: string): Promise<void> {
        try {
            this.caseBook.description = description;
            this.caseBook = await this.casesApi.update(this.caseBookId, this.state.company(), this.caseBook);
        } catch (error) {
            Toast.error(`Updating the Case's description was unsuccessful, please try again later.`);
        }
    }

    private async updateTitle(title: string): Promise<void> {
        try {
            this.caseBook.title = title;
            this.caseBook = await this.casesApi.update(this.caseBookId, this.state.company(), this.caseBook);
        } catch (error) {
            Toast.error(`Updating the Case's title was unsuccessful, please try again later.`);
        }
    }

    private async downloadReport(filename?: string): Promise<void> {
        // This function should only be exposed when the investigation contains an investigation report
        if (this.investigation.investigationReports.length === 0) {
            Toast.warning(`Case does not seem to contain any reports.`);
            return;
        }

        // Use given filename or select first one from the list
        filename = filename ? filename : this.investigation.investigationReports[0];

        try {
            // Download investigation report document
            const fileResponse = await this.investigationsApi.getDocumentById(this.investigation.id, filename, this.state.company());
            // Initiate a save to the user for this document
            saveAs(fileResponse.data, filename);
        } catch (error) {
            console.error('Error occurred when downloading investigation report', error);
            Toast.error(`Report for Case could not be downloaded.`);
        }
    }
}
