import { autoinject, observable } from 'aurelia-framework';
import { saveAs } from 'file-saver';
import { Toast } from 'utilities/toast';
import { UxBlade } from './../../components/layout/ux-blade/ux-blade';
import { BusinessMapsApiClient, CaseBookLightweightExtended, CaseBookStatusTypes, CasesApiClient, Investigation, InvestigationsApiClient, InvestigationStatus, SortableCasesFields } from './../../services/cyber-api';
import { StateApi } from './../../services/state-api';
import { CaseUtilities } from '../../utilities/case-utilities';

@autoinject
export class Cases {
    private enableBusinessFeatures: boolean = false;
    private cases: CaseBookLightweightExtended[] = undefined;
    private blade: UxBlade;
    @observable() private searchValue: string;
    private SortableCasesFields: typeof SortableCasesFields = SortableCasesFields;
    private sortSettings: ICasesColumnSort = {
        column: SortableCasesFields.Updated_at,
        direction: 'desc'
    };
    private investigationStatus: typeof InvestigationStatus = InvestigationStatus;
    private investigations: Investigation[];
    private investigationsTotalCount: number;
    private investigationsInProgressCount: number;
    private investigationsCompletedCount: number;
    private openCasesCount: number;
    private closedCasesCount: number;
    private creditBalance: number = -1;
    private readonly take: number = 100;
    private skip: number = 0;
    private loading: boolean = true;
    private totalCasesCount: number;
    private resetCasesTimer: any; // Holds a timer that is executed when filter values change, older instances are cancelled so no double resets occur

    constructor(
        private businessMapsApi: BusinessMapsApiClient,
        private casesApi: CasesApiClient,
        private state: StateApi,
        private investigationsApi: InvestigationsApiClient
    ) { }

    public async bind(): Promise<void> {
        this.resetCases();
        this.retrieveBalance();
        this.retrieveInvestigations();
        this.retrieveCasesStats();
    }

    private async searchValueChanged(): Promise<void> {
        this.queueResetCases();
    }

    private queueResetCases(delay: number = 50): void {
        if (this.resetCasesTimer) clearTimeout(this.resetCasesTimer);
        this.resetCasesTimer = setTimeout(async () => {
            await this.resetCases();
        }, delay);
    }

    private async resetCases(): Promise<void> {
        this.cases = [];
        this.skip = 0;
        this.totalCasesCount = undefined;
        await this.fetchCases(0, true, false);
    }

    private async retrieveBalance(): Promise<void> {
        // Enable/Disable 'business' features
        this.enableBusinessFeatures = await CaseUtilities.shouldEnableBusinessFeatures(this.businessMapsApi, this.state.company());

        if (this.enableBusinessFeatures)
            this.creditBalance = await this.investigationsApi.getBalance(this.state.company());
        else
            this.creditBalance = 0;
    }

    private async fetchCases(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // When at the end of the list and no more cases are available, short-circuit as there's nothing left
        // to fetch
        if (this.totalCasesCount === this.cases.length) return;

        this.loading = true;

        const pagedCases = await this.casesApi.getAllLightweightExtended(this.state.company(),
            this.searchValue,
            this.take,
            this.skip,
            this.sortSettings.column,
            this.sortSettings.direction,
            undefined,
            undefined,
            undefined
        );

        if (!pagedCases) return;

        this.cases = this.cases.concat(pagedCases.items);
        this.totalCasesCount = pagedCases.total;

        this.skip += this.take;

        this.loading = false;
    }

    private async retrieveCasesStats(): Promise<void> {
        this.casesApi.getAll(this.state.company(), undefined, 0, 0, undefined, undefined, undefined, undefined, CaseBookStatusTypes.Open)
            .then((response) => this.openCasesCount = response.total);

        this.casesApi.getAll(this.state.company(), undefined, 0, 0, undefined, undefined, undefined, undefined, CaseBookStatusTypes.Closed)
            .then((response) => this.closedCasesCount = response.total);
    }

    private async retrieveInvestigations(): Promise<void> {
        const response = await this.investigationsApi.getAll(this.state.company(), 1000, 0);
        this.investigations = response.items;
        this.investigationsTotalCount = this.investigations.filter((investigation) => investigation.status !== InvestigationStatus.Cancelled).length;
        this.investigationsInProgressCount = this.investigations.filter((investigation) => investigation.status === InvestigationStatus.Requested || investigation.status === InvestigationStatus.Inprogress).length;
        this.investigationsCompletedCount = this.investigations.filter((investigation) => investigation.status === InvestigationStatus.Completed).length;
    }

    public async createCase(): Promise<void> {
        await this.blade.show();
    }

    private async afterBladeHide(): Promise<void> {
        await this.resetCases();
    }

    private async setSort(column: SortableCasesFields): Promise<void> {
        // If sorting is set for a different column than what sorting is currently active on, set default to descending
        if (this.sortSettings.column !== column)
            this.sortSettings = {
                column,
                direction: 'desc'
            };
        else if (this.sortSettings.column === column)
            // Invert the sorting direction if the current column is already selected
            switch (this.sortSettings.direction) {
                case 'asc': this.sortSettings.direction = 'desc'; break;
                case 'desc': this.sortSettings.direction = 'asc'; break;
            }

        await this.resetCases();
    }

    private async downloadReport(investigation: Investigation, filename?: string): Promise<void> {
        // This function should only be exposed when the investigation contains an investigation report
        if (investigation && investigation.investigationReports.length === 0) {
            Toast.warning(`Case does not seem to contain any reports.`);
            return;
        }

        // Use given filename or select first one from the list
        filename = filename ? filename : investigation.investigationReports[0];

        try {
            // Download investigation report document
            const fileResponse = await this.investigationsApi.getDocumentById(investigation.id, filename, this.state.company());
            // Initiate a save to the user for this document
            saveAs(fileResponse.data, filename);
        } catch (error) {
            console.error('Error occurred when downloading investigation report', error);
            Toast.error(`Report for Case could not be downloaded.`);
        }
    }
}

interface ICasesColumnSort {
    column: SortableCasesFields;
    direction: 'asc' | 'desc';
}
